<script setup>
const props = defineProps({
  id: String,
  checked: {
    type: Boolean,
    default: false
  },
  label: String
})

const isChecked = ref(props.checked)

const reset = () => {
  if (!props.label.includes('All')) {
    isChecked.value = false;
  }
  else {
    isChecked.value = true;
  }
}

const notAll = () => {
  if (props.label.includes('All')) {
    isChecked.value = false;
  }
}

const check = () => {
  isChecked.value = true;
}

const uncheck = () => {
  isChecked.value = false;
}

const getLabel = () => {
  return props.label
}

defineExpose({
  reset,
  notAll,
  check,
  uncheck,
  getLabel
})

const emit = defineEmits(['onUpdate'])
const handleCheck = (event) => {
  isChecked.value = event.target.checked
  emit('onUpdate', event.target.checked);
}
</script>

<template>
  <div class="btn-option-container flex-grow-1" >
    <input type="checkbox" class="btn-check" :id="id" autocomplete="off" :checked="isChecked" @change="handleCheck">
    <label class="btn btn-outline-primary px-2 py-0 rounded-2 filter-option-btn" :for="id" >
      <span class="bi-check" v-if="isChecked"></span>
      <span class="option-value">{{ label }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.btn-check + .btn:hover {
  border: solid 1px $color-primary;
  background-color: $color-accent;
}
.btn-check {
  &:checked + .btn {
    border: 1px solid $color-primary;
    background-color: white;
    font-weight: 600;
  }
}
label.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  width: auto;  
  min-width: 135px;
  border-radius: 0px;
  border-color: $color-light-gray;
  height: 47px;
  .bi-check {
    font-size: 30px;
  }
  &:hover {
    background-color: $color-accent;
    border: 1px solid $color-primary;
  }
}
</style>