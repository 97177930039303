<script setup>

const props = defineProps({
  filter: Object,
  oneSelectedOnly: {
    type: Boolean,
    default: false
  },
  updateOnSelection: {
    type: Boolean,
    default: false
  },
  locationSearchFilter: {
    type: Boolean,
    default: false  
  }
})

const {slugify, searchLocationFilter} = useLocationData();
const {autocomplete, reverseGeocode} = useGoogleMaps();
const emit = defineEmits(['onSelection'])

const search = ref(props.filter.value)
const predictions = ref([])
const selection = ref(props.filter.value);
const options = ref(props.filter.options)
const predictionsContainer = ref();
const predState = ref(false)
let clonedValues = [...props.filter.value]
const showAutocompleteDropdown = ref(false), locationFilter = ref()

const handlePredictionSelection = async (description) => {
  search.value = description;
  showAutocompleteDropdown.value = false;
  const miles = props.locationSearchFilter ? 90 : 250;
  const {data: locations, error} = await searchLocationFilter(search.value, miles);  
  const locationIds = locations.value.data.map((location) => {
    return location.pulseId;
  })
  predState.value = false;
  props.filter.search = description;
  emit('onSelection', {
    filter: props.filter,
    value: locationIds
  });
}



const handleSelection = () => {
  if (selection.value.length == 0) {
    selection.value = [props.filter.all]
    checkboxes.value.forEach(checkbox => {
      checkbox.reset();
    })
  }
  emit('onSelection', {
    filter: props.filter,
    value: selection.value
  });
  predState.value = false;

  // Updating the clonedValues
  clonedValues = [];
  for (const v of selection.value) {
    clonedValues.push(v)
  }
}

const handleClose = () => {
  selection.value.splice(0, selection.value.length)
  for (const value of clonedValues) {
    selection.value.push(value)
  }

  checkboxes.value.forEach(checkbox => {
    if (clonedValues.includes(checkbox.getLabel())) {
      checkbox.check()
    }
    else {
      checkbox.uncheck()
    }
  })
}


const checkOptions = (option) => {
  const checked = event.target.checked;
  if (checked) {
    if (option.includes(props.filter.all)) {
      selection.value = [props.filter.all]
      checkboxes.value.forEach(checkbox => {
        checkbox.reset();
      })
    }
    else {
      const position = selection.value.indexOf(props.filter.all);
      selection.value.push(option)
      if (position >= 0) {
        selection.value.splice(position, 1);
        checkboxes.value.forEach(checkbox => {
          checkbox.notAll();
        })
      }
    }
    
  } else {
    const position = selection.value.indexOf(option);
    selection.value.splice(position, 1);
  }

  if (props.updateOnSelection) {
    handleSelection();
  }
}

const isSelected = (option) => {
  return selection.value.includes(option)
}

const preds = computed(() => {
  return {
    'd-none': !predState.value,
    'd-block': predState.value,
  }
})

const handleDisplaySuggestions = (event) => {
  predState.value = event.type === 'focus'
}

const checkboxes = ref([]);
const reset = () => {  
  if (props.filter.type === 'fcp-location' || props.filter.type === 'location') {
    locationFilter.value.setSearchText('')
  }
  else {
    selection.value = [props.filter.all]
    checkboxes.value.forEach(checkbox => {
      checkbox.reset();
    })
  }
  
  if (props.updateOnSelection) {
    handleSelection();
  }
  predState.value = false;
  
}

defineExpose({  
  reset,
  handleSelection,
  handleClose
})

const handleFocusout = (searchQuery) => {
  if (searchQuery === '') {
    search.value = searchQuery;
    props.filter.search = searchQuery
    emit('onSelection', {
      filter: props.filter,
      value: searchQuery
    });
    predState.value = false;
  }
}

const smoothScroll = () => {
  const canvasBody = document.getElementById('grid-canvas-body');
  canvasBody.scrollTo({
    top: canvasBody.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
}

const handleAutocomplete = () => {
  if ((props.filter.type === 'fcp-location' || props.filter.type === 'location') && props.filter.lastFilter) {
    smoothScroll()   
  }
  
}

const handleFocus = () => {  
  if ((props.filter.type === 'fcp-location' || props.filter.type === 'location') && props.filter.lastFilter) {
    smoothScroll()   
  }
}

onMounted(() => {
  
  const locationWrapper = document.getElementById('location-filter-wrapper');
  if (locationWrapper && (props.filter.type === 'fcp-location' || props.filter.type === 'location') && props.filter.lastFilter) {
    locationWrapper.addEventListener('click', (event) => {
      smoothScroll() 
    })    
  }
  
})


</script>

<template>
  <div :id="filter.type" class=" align-items-center hide border-0 p-2 filter-prod-mobile" >
    <div class="text-start mb-3">      
      <h3>{{ filter.type === 'fcp-location' || filter.type === 'location' ? filter.mobileTitle : filter.title }}</h3>
    </div>
    <div class="toast-body" >
      <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group" v-if="filter.type !== 'fcp-location' || filter.type === 'location'">
        <FilterCardProductCheckbox ref="checkboxes" v-for="option in options" :key="`mobile-${filter.type}-${slugify(option)}`" :id="`mobile-${filter.type}-${slugify(option)}`" :checked="isSelected(option)" :label="option" @onUpdate="checkOptions(option)"  />
      </div>
      
      <div class="location-container mb-6" id="location-filter-wrapper" v-if="filter.type === 'fcp-location' || filter.type === 'location'">
        <LocationSearchForm :showSearchButton="false" :placeholderText="filter.mobilePlaceholder"  @onAutocomplete="handlePredictionSelection" :searchQuery="filter.search" :overrideSearch="true" @onFocusout="handleFocusout" ref="locationFilter" @onCreateAutocomplete="handleAutocomplete" :onFocus="handleFocus" />
      </div>
      
    </div>
  </div>
</template>

<style lang="scss" scoped>

.filter-prod-mobile {
  label,
  h3 {
    color: $color-primary;
  }
}
.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.location-container {
  position: relative;
  .icon-map-pointer {
    position: absolute;
    top: 15px;
    left: 10px;
    font-size: 20px;
    color: $color-gray;
  }

  .bi-geo-alt-fill {
    color: $color-id-green;
  }

  &:hover, &:focus {
    #predictions, .form-control {
      border: solid 1px #1f1f1f;
    }
  }

  #fcp-location-search {
    height: calc(3rem + 2px);
    line-height: 1.25;
    &.no-icon {
      padding-left: 21px !important;
    }
  }

  #label-search {
    left: 27px;
    &.no-icon {
      left: 10px;
    }    
    padding: 0.5rem 0.75rem;
    top: 5px;
  }

  .form-floating {
    z-index: 101;
  }

  #predictions {    
    background-color: #fff;    
    position: absolute;
    width: 100%;
    border-radius: 0 0 20px 20px;
    padding-top: 30px !important;
    top: 15px;
    border: solid 1px #d9d9d9;
    -webkit-box-shadow: 0 3px 12px 0 rgba(0,0,0,0.16);
    box-shadow: 0 3px 12px 0 rgba(0,0,0,0.16);

    .toast-body {
      z-index: 105;
      position: relative;
    }

    &:hover, &:focus {
      border: solid 1px #1f1f1f;
    }

    .location-suggestions-item {
      border: none;
      background-color: transparent ;
      &:focus-visible {
        outline-color: #a6a6a6;
        outline-width: 3px;
      }
    }
  }
}
</style>